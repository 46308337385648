<template>
    <div class="contacts w-100 black">
      <div class="w-100 d-flex justify-content-center black">
        <v-form id="policy-form" class="mt-lg-16" style="display: block; margin: auto;">
          <div class="form-content">
            <v-btn text fab @click="$emit('close')" style="float: right;" class="d-none d-sm-block"><v-icon
                large>mdi-close</v-icon></v-btn>
            <v-btn text fab @click="$emit('close')" style="top: -60px; right: -30px; position: absolute;"
              class="d-sm-none"><v-icon large>mdi-close</v-icon></v-btn>
            <v-row class="policy-content" @scroll="onScroll">
              <v-col cols="12" class="pb-16 mb-16">
                <div class='intro mt-2'>
                  <p class='w-100 text-center'>
                    <strong>
                      TERMS AND CONDITIONS
                    </strong>
                  </p>
                  <v-card class="elevation-0 transparent">
                    <v-card-text>
                      These are the terms and conditions for access to and use of the IGNOTO platform. It is important that you read them carefully. By accepting these terms and conditions, you are entering into a legal agreement under Portuguese law. If you do not accept them, you will not be able to use this platform. Dreamdeal Technologies, Unipessoal Lda. updated these Terms and Conditions on May 6, 2024, with effect from the same date.

                    </v-card-text>
                  </v-card>
                  <div>
                    <p class="w-100 text-center">
                      Index
                    </p>
                  </div>
                  <div class='index mt-5'>
                    <ol type="1">
                      <li><a href='#object'>Object</a></li>
                      <li><a href='#services'>Services</a></li>
                      <li><a href='#payment'>Payment</a></li>
                      <li><a href='#sla'>Minimum Services/SLA</a></li>
                      <li><a href='#use_of_service'>Use of Service</a></li>
                      <li><a href='#force'>Force Majeure</a></li>
                      <li><a href='#responsibilty'>Warranty and Civil Liability</a></li>
                      <li><a href='#close_policy'>Cancellation Policy</a></li>
                      <li><a href='#final'>Final Provisions</a></li>
                      <li><a href='#gloss'>Glossary</a></li>

                    </ol>
                  </div>
                </div>
                <div id="object">
                  <v-card class="transparent">
                    <v-card-title>1. Object</v-card-title>
                    <v-card-text>
  1.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Agreement to these Terms and Conditions establishes a contractual relationship (“Contract”) between the Client (as defined below in the Glossary) and Dreamdeal Technologies, Unipessoal Lda., a limited liability company registered under Portuguese law with NIPC 516178733, located at Rua Alfredo Keil, 371, 3E, 4150-048 Porto (hereinafter referred to as “Dreamdeal”). The Client and Dreamdeal are hereinafter also referred to as the “Party” and collectively as the “Parties”.<br /><br />
  
  1.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These terms and conditions come into effect on the date they are signed by the Client or their Representative, or on the date they declare their acceptance, regardless of the manner in which this declaration is made. <br /><br />
  
  1.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal grants the Client a temporary, worldwide, non-exclusive, and non-sub-licensable right to access the IGNOTO software, under the Terms and Conditions set forth herein, limited to a period of one year, automatically renewable for successive equal periods, unless otherwise agreed by the Parties.<br /><br />
  
  1.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the case of a free trial subscription, the right referred to in section 1.3 is only granted for a period of 30 days.<br /><br />
  
  1.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal does not transfer to the Client, Employee, or User ownership of copyrights, industrial property, or other related rights.<br /><br />
  
  1.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The access right referred to in section 1.3 may be sublicensed by the Client to their Employees, in the manner in which it is granted, but only within the scope of the respective employment or service relationship, limited by the period and terms of the subscription for services contracted by the Client.<br /><br />
  
  1.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The transmission of the access right referred to in section 1.3 is expressly prohibited, except as provided in section 1.5.<br /><br />
  
  1.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Client may not, nor allow Employees or third parties to:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;copy, reproduce, distribute, modify, adapt, alter, translate, or create derivative works of the software or applications;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;assign, sublicense, lease, rent, lend, transfer, disclose, or otherwise make available the software or other works;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  c) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;incorporate the software into other software;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  d) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;reverse engineer, decompile, disassemble the software, or otherwise attempt to obtain its source code or its algorithmic nature, or decode, decrypt, or circumvent any security measure of the software, remove or bypass the software protection without authorization;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  e) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;perform any action that would result in the software or a derivative work of it being licensed under open-source terms, including, among others, combining the software or a derivative work with open-source software by incorporation, linking, or otherwise;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  f) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;create a derivative work from the IGNOTO software;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  g) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;make available or reproduce in any form the source code of the IGNOTO software;<br /><br />
  
  1.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal reserves the right to unilaterally amend these Terms and Conditions.
</v-card-text>
                  </v-card>
                </div>
                <div id="services">
                  <v-card class="transparent">
                    <v-card-title>2. Services</v-card-title>
                    <v-card-text>
  2.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IGNOTO is an online software platform for managing reports.<br /><br />
  
  2.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The data is available online through the client area of the respective application and stored in a cloud owned by Dreamdeal, on servers physically located within the European Union, complying with all GDPR guidelines and cybersecurity regulations in effect in the EU.<br /><br />
  
  2.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal offers standardized subscription plans, listed on ignoto.eu and described in the following paragraphs, as well as tailored subscription plans (“Tailored Plan”), regulated by Specific Terms and Conditions.<br /><br />
  
  2.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Client commits to a specific subscription plan when they make a declaration to that effect, regardless of the form.<br /><br />
  
  2.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <b>Plan i1</b> has the following features:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This plan is for private companies or public institutions with up to 99 employees;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The storage of the Client's and their Employees' data entered into the IGNOTO platform is done on an SSD disk owned by Dreamdeal, limited to a maximum of 4 GB.<br /><br />
  
  2.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <b>Plan i2</b> has the following features:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This plan is for private companies or public institutions with up to 299 employees;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The storage of the Client's and their Employees' data entered into the IGNOTO platform is done on an SSD disk owned by Dreamdeal, limited to a maximum of 8 GB.<br /><br />
  
  2.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <b>Plan i3</b> has the following features:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This plan is for private companies or public institutions with up to 999 employees;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The storage of the Client's and their Employees' data entered into the IGNOTO platform is done on an SSD disk owned by Dreamdeal, limited to a maximum of 12 GB.<br /><br />
  
  2.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <b>Plan iP1</b> has the following features:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This plan is for public institutions: Municipalities with a population of up to 25,000 inhabitants. Foundations, Hospitals, IPSS, among others, with a network of up to 25,000 employees/associates.<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The storage of the Client's and their Employees' data entered into the IGNOTO platform is done on an SSD disk owned by Dreamdeal, limited to a maximum of 6 GB.<br /><br />
  
  2.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <b>Plan iP2</b> has the following features:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This plan is for public institutions: Municipalities with a population between 25,000 and 55,000 inhabitants. Foundations, Hospitals, IPSS, among others, with a network of between 25,000 and 55,000 employees/associates.<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The storage of the Client's and their Employees' data entered into the IGNOTO platform is done on an SSD disk owned by Dreamdeal, limited to a maximum of 10 GB.<br /><br />
  
  2.10. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <b>Plan iP3</b> has the following features:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This plan is for public institutions: Municipalities with a population above 55,000 inhabitants. Foundations, Hospitals, IPSS, among others, with a network of over 55,000 employees/associates.<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The storage of the Client's and their Employees' data entered into the IGNOTO platform is done on an SSD disk owned by Dreamdeal, limited to a maximum of 15 GB.<br /><br />
  
  2.11. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All plans include: A customizable presentation model (it is possible to change the cover layout (color, font, text); Embedding via snippet or access link; Access to the admin panel; Notifications and SLA control; SSO Integration; Unlimited number of administrators; Unlimited number of reports; 2FA authentication; E2E encryption; Processes in compliance with ISO 27001 Certification; 24-hour support.<br /><br />
  
  2.12. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payment for subscription plans is made under the terms of the next chapter.
</v-card-text>

                  </v-card>
                </div>
                <div id="payment">
                  <v-card class="transparent">
                    <v-card-title>3. Payment</v-card-title>
                    <v-card-text>
  3.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Client is responsible for the payment of all prices, taxes, and charges due in exchange for the Services, according to the contracted subscription model.<br /><br />
  
  3.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payment is made via Multibanco reference, credit card, direct debit, or bank transfer (upon prior written request to the email: info@ignoto.eu) to the IBAN provided by Dreamdeal.<br /><br />
  
  3.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By subscribing to one of our plans, the Client pays an annual fee, with the due date being the day after agreeing to these Terms and Conditions.<br /><br />
  
  3.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The base prices established for each plan are as follows:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>PRIVATE COMPANIES</b><br /><br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plan i1</b>: €719.88 per year + VAT at the applicable legal rate, equivalent to €59.99/month + VAT;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plan i2</b>: €1,199.88 per year + VAT at the applicable legal rate, equivalent to €99.99/month + VAT;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  c) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plan i3</b>: €2,039.88 per year + VAT at the applicable legal rate, equivalent to €169.99/month + VAT.<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>PUBLIC COMPANIES</b><br /><br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plan iP1</b>: €839.88 per year + VAT at the applicable legal rate, equivalent to €69.99/month + VAT;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plan iP2</b>: €1,439.88 per year + VAT at the applicable legal rate, equivalent to €119.99/month + VAT;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  c) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plan iP3</b>: €2,399.88 per year + VAT at the applicable legal rate, equivalent to €199.99/month + VAT.<br /><br />
  
  3.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Promotions: Promotional offer for a <b>limited</b> time, valid only for subscriptions made during the period when a promotion is indicated on the subscription page:<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is necessary to subscribe during the promotion period;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This promotion may be canceled or modified at any time without prior notice;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  c) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The promotion is only applicable to subscriptions that display the discount offer message;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  d) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The promotion can only be used by the account holder who received the credit and cannot be transferred to third parties;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  e) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The promotion cannot be combined with other offers unless the promotion indicates otherwise;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  f) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The promotion is only applicable to finalized requests that meet all the promotion requirements;<br /><br />
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  g) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upon renewal (after 12 months), the base price of the plan will be applied and not the promotional price.<br /><br />
  
  3.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The subscription will be automatically renewed after 12 months, unless the Client cancels the subscription within 30 days. The billing for the subscription will be issued on the 30th day following the initial subscription date. The subscription is billed in advance, and under no circumstances is refundable.<br /><br />
  
  3.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There will be no refunds or credits for partial months of subscription, upgrades/downgrades, or refunds for unused months with an open account. Payments made for subscriptions are non-refundable under any circumstances, including if the use of the website <a href="www.ignoto.eu" target="_blank">www.ignoto.eu</a> is discontinued or the account is closed.<br /><br />
  
  3.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the Client requests or intends to upgrade/downgrade their plan, the payment for this will be immediate and automatically due, with the payment due in the next billing period.<br /><br />
  
  3.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Failure to pay the annual fee grants Dreamdeal the right to terminate the contract, according to the Cancellation Policy in section 8.<br /><br />
  
  3.10. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Based on the principle of equality, no exceptions will be considered.<br /><br />
</v-card-text>
                  </v-card>
                </div>
                <div id="sla">
                  <v-card class="transparent">
                    <v-card-title>4. Minimal services (Service Level Agreement)</v-card-title>
                    <v-card-text>
  4.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal commits to providing Services with an uptime of 96%, granting access to data and information limited by the selected SSD Disk capacity, as described in points 2.5. and 2.6.<br /><br />

  4.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the service is not provided as defined in point 4.1., this will constitute a Service Failure, compensable as described in point 4.7.<br /><br />

  4.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the Client believes that the services are not being delivered with the quality described in the previous paragraph, they must notify Customer Support by phone or email within one day after the alleged service failure.<br /><br />

  4.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the notification referred to in point 4.3., the Client must provide Customer Support with a detailed description of the alleged service failure, indicating, in particular, the duration, URLs, pages, and affected services, as well as any attempts made to resolve the issue.<br /><br />

  4.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer Support will gather all available information regarding the report, carefully analyze the situation, and determine whether the event qualifies as a Service Failure.<br /><br />

  4.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The following will not be considered Service Failures:<br /><br />

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Factors outside Dreamdeal’s control or beyond its reasonable control;<br /><br />

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Factors related to the hardware or software of the User or third parties;<br /><br />

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Actions or omissions by the Client, Employees, or third parties;<br /><br />

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Misuse or improper use of the service by the Client, Employees, or third parties.<br /><br />

  4.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the situation is qualified by Customer Support as a Service Failure, a discount will be applied to the next billing period, calculated as follows: Discount = (minutes of Service Failure × Client's annual subscription fee) ÷ total minutes in the year, depending on the case.<br /><br />

  4.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the case of an alleged Service Failure reported by the Client, Dreamdeal will always analyze the Client's information in compliance with Portuguese data protection law and the confidentiality commitment as outlined in the following <u>Privacy Policy</u>.<br /><br />

  4.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The provisions of the previous point do not apply to free subscriptions.<br /><br />
</v-card-text>
                  </v-card>
                </div>
                <div id="use_of_service">
                  <v-card class="transparent">
                    <v-card-title>5. Use of Service </v-card-title>
                    <v-card-text>
  5.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Client must ensure that their Employees use the IGNOTO Services and software solely for their intended purposes, and in accordance with the instructions in the manuals, guidelines, warranty terms, and any other applicable terms and conditions;<br /><br />

  5.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The use of the Services for purposes contrary to Portuguese law is expressly prohibited.<br /><br />

  5.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any malfunctions, errors, or anomalies in the software must be reported by the Client to Customer Support by phone or email within one day.<br /><br />

  5.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If it is determined that the Service is being used for unlawful or unauthorized purposes, Dreamdeal reserves the right to terminate the contract and/or suspend the Service.<br /><br />

  5.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal is not responsible for any insufficiency of the Services to meet expectations regarding performance, benefits, or expected effects on the Client's business or productivity, other than those described and anticipated in these Terms and Conditions.<br /><br />
</v-card-text>
                  </v-card>
                </div>
                <div id="force">
                  <v-card class="transparent">
                    <v-card-title>6. Force Majeure</v-card-title>
                    <v-card-text>
  6.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal is not responsible for any failure or delay in providing the Service due to Force Majeure events.<br /><br />
  6.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Force Majeure" refers to any event or occurrence that is beyond Dreamdeal's reasonable control, regardless of its foreseeability at the time of the Contract, and by virtue of which it would not be reasonable to expect the performance or fulfillment of its obligations. This includes, but is not limited to, acts of God, natural disasters (such as earthquakes, thunderstorms, hurricanes, storms, floods, volcanic activity, or other extreme weather conditions), strikes, blockades, armed conflict, terrorist attacks, coups, riots, sabotage, vandalism, breakdowns in facilities or machinery, power failures, cyberattacks, piracy, or non-fulfillment by Dreamdeal's suppliers or any third parties on which the Services depend.<br /><br />
  6.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If there are reasons to believe that the Force Majeure event will continue for more than three consecutive months, Dreamdeal reserves the right to terminate the Contract in accordance with the Cancellation Policy described in point 8.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="responsibilty">
                  <v-card class="transparent">
                    <v-card-title>7. Warranty and Civil Liability </v-card-title>
                    <v-card-text>
  7.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These Terms and Conditions do not grant the Client the right to claim any compensation from Dreamdeal for civil liability, whether contractual or extracontractual, for any (i) loss of profits; (ii) loss of sales or business; (iii) loss of agreements or contracts; (iv) loss of anticipated savings; (v) loss of use or corruption of software, data, or information; (vi) loss or damage to goodwill; and (vii) indirect or consequential damages.<br /><br />
  7.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TO THE EXTENT PERMITTED BY LAW, AND WITHOUT PREJUDICE TO THE EXCLUSIONS AND LIMITATIONS SET OUT IN THESE TERMS AND CONDITIONS, WE DISCLAIM ALL WARRANTIES AND RENOUNCE ALL LIABILITY FOR ANY ACT OR OMISSION BY THE USER OR THIRD PARTIES.
</v-card-text>
                  </v-card>
                </div>
                <div id="close_policy">
                  <v-card class="transparent">
                    <v-card-title>8. Cancellation Policy</v-card-title>
                    <v-card-text>
  8.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dreamdeal reserves the right to terminate the contract unilaterally in the following cases:<br /><br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Failure to pay any installment by its due date, as described in point 3.;<br /><br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Improper use of the Service by the Client, as described in point 5.;<br /><br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Force Majeure event lasting for more than three consecutive months, as described in point 6.;<br /><br />
  8.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Client may terminate the contract by notifying Dreamdeal in writing with at least 30 days' notice, and can do so by registering the request through their personal area (options, deactivate account).<br /><br />
  8.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There will be no refund or credits for partial subscription months, upgrade/downgrade refunds, or refunds for months not used with an active account. Amounts paid for subscriptions are non-refundable or reimbursable under any circumstances, including in the case of transfer of use of the website <a href="www.ignoto.eu" target="_blank">www.ignoto.eu</a> or account closure.<br /><br />
  8.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case of termination by the Client, the Client will remain liable for any amounts that may be unpaid during the period in which the Service was active.
</v-card-text>
                  </v-card>
                </div>
                <div id="final">
                  <v-card class="transparent">
                    <v-card-title>9. Final Provisions</v-card-title>
                    <v-card-text>
  9.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the purpose of fulfilling this Agreement, any communications with Dreamdeal should be made using the following contact details:<br /><br />
  <ul>
    <li>info@ignoto.eu</li>
    <li>+351 220 280 430</li>
  </ul><br />
  9.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any dispute or question arising from the interpretation, integration, execution, compliance, and validity of this agreement, which cannot be resolved by mutual consent, will be settled through judicial means and/or alternative dispute resolution methods available under Portuguese law, to the exclusion of any other means.<br /><br />
  9.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This agreement is governed by Portuguese law, specifically the provisions of Decree-Law No. 252/94, of October 20.<br /><br />
  9.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By accepting the terms of this agreement, the Merchant agrees to be bound by its content.
</v-card-text>
                  </v-card>
                </div>
                <div id="gloss">
                  <v-card class="transparent">
                    <v-card-title>Glossary</v-card-title>
                    <v-card-text>
  Ao longo destes termos e condições, há expressões que se repetem, e que simplificamos para uma mais fácil leitura. Para não deixar dúvidas sobre os respetivos significados, deixamos aqui uma lista:
  <ul>
    <li>
      <b>APIs</b> - Set of tools, definitions, and protocols for creating software applications;
    </li>
    <li>
      <b>Cliente</b> - An individual or legal entity wishing to subscribe to the use of the IGNOTO software, under any of the available plans, and who is a party to this agreement. The customer data is that which appears in the Form, entered by the Client or their Representative, and is considered fully reproduced for legal purposes;
    </li>
    <li>
      <b>Colaborador</b> (or <b>Colaboradores</b>) - Individuals authorized by the Client to use the subscribed services, within the framework of an employment relationship or service provision with the Client;
    </li>
    <li>
      <b>Disco SSD</b> - Solid-state drive for storing computer data;
    </li>
    <li>
      <b>Dispositivo ou Dispositivos</b> - Any computer, tablet, or smartphone that the User may be using to access the Services;
    </li>
    <li>
      <b>Formulário</b> - Contact form available on the ignoto.eu website, through which the subscription is chosen and the data of the individual or legal entity Client is entered;
    </li>
    <li>
      <b>IGNOTO (“software IGNOTO”, “plataforma IGNOTO”)</b> - Collective term for the online software service for complaint management, owned by Dreamdeal Technologies, Unipessoal, Lda., which is subject to these terms and conditions;
    </li>
    <li>
      <b>Representante do Cliente</b> (or <b>“Representante”</b>) - An individual acting on behalf of the Client, if the Client is a legal entity;
    </li>
    <li>
      <b>Serviço</b> (or <b>Serviços</b>) - Collective term for all information, tools, functionalities, accessible through ignoto.eu, via APIs, or any other software or website interconnected with the IGNOTO platform;
    </li>
    <li>
      <b>Utilizador</b> - Any person, whether a Client or a Colaborador, who uses the Services.
    </li>
  </ul>
</v-card-text>
                  </v-card>
                </div>
              </v-col>
              <div class="sroll-down text-center">
                <p class="text-uppercase mb-0">Scroll Down</p>
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </v-row>
          </div>
        </v-form>
      </div>
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: 'TermsConditions',
    data() {
      return {
        isOpen: false,
      }
    },
    created() {
    },
    beforeCreate() {
      const el = document.body
      el.classList.remove('home')
      el.classList.remove('colorize')
    },
    methods: {
      onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          document.querySelector('.policy-content').className += ' active'
        } else {
          document.querySelector('.policy-content').classList.remove('active')
        }
      },
      toggleMenu(e) {
        e.preventDefault()
        e.stopPropagation()
        this.isOpen = !this.isOpen
      }
    }
  }
  </script>
  
  <style lang="scss">
  .vue-slider.policy .vert-custom-dot {
    border-color: #0837fb;
  }
  
  .contact-center {
    font-size: 18px;
  
    span {
      color: #0837fb;
    }
  }
  
  .page-title {
    font-family: "Bebas Neue", monospace;
    font-size: 40px;
    color: #0837fb;
  }
  
  .sroll-down {
    position: absolute;
    bottom: 0;
    color: #FFFFFF;
    font-weight: bold;
    width: 105%;
    z-index: 5;
    padding-bottom: 25px !important;
    padding-top: 25px;
    transition: 0.4s all;
    opacity: 1;
  
    img {
      width: 25px;
    }
  
    &::after {
      background: rgb(0, 0, 0);
      background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
      content: "";
      position: absolute;
      width: 100%;
      top: -45px;
      height: 148%;
      left: 0;
      z-index: -1;
    }
  }
  
  #policy-form {
    max-width: 1000px;
    padding: 2rem 5rem 0 5rem;
    position: relative;
    border-radius: 1rem;
  
    // margin-top: 7rem;
    .policy-content {
      overflow-y: auto;
      height: 85vh;
      scrollbar-width: none;
      -ms-overflow-style: none;
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      &.active {
        .sroll-down {
          opacity: 0;
        }
      }
    }
  
    .form-content {
      position: relative;
      z-index: 5;
    }
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: -5px;
      background: #000;
      border-radius: 0.8rem;
    }
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      margin: -13px;
      background: linear-gradient(to bottom, #FFFFFF, #000000);
      border-radius: 1rem;
    }
  }
  
  @media only screen and (max-width : 1600px) {
    #policy-form {
      max-width: 850px;
      padding: 2rem 3rem 0 3rem;
      margin-top: 2rem;
  
      h3 {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
  
  @media only screen and (max-width: 1263px) {
    #policy-form {
      padding: 40px;
      padding-top: 80px;
    }
  }
  </style>
  